import * as React from "react";
import {Drawer, Slide, useScrollTrigger} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {Search} from "./Search";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {Kennzahl} from "../model/Kennzahl";

type Props = {
    kennzahlen: Kennzahl[];
    female: boolean;
    selectElement(k: Kennzahl | undefined): void;
}

export const GuideNav: React.FC<Props> = (props: Props) => {
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
    const [isSearching, setIsSearching] = React.useState<boolean>(false);
    const trigger = useScrollTrigger();

    const search = () => {
        setIsSearching(true);
        setDrawerOpen(true);
    };

    const categories: string[] = [];
    props.kennzahlen.forEach(
        value => {
            if (categories.indexOf(value.section) === -1) categories.push(value.section)
        }
    );

    const close = () => {
        setDrawerOpen(false);
        setIsSearching(false);
    };

    const selectAndClose = (e: Kennzahl | undefined) => {
        props.selectElement(e);
        setDrawerOpen(false);
        setIsSearching(false);
    };

    return (<>
        <Drawer open={drawerOpen} onClose={close}
                onBackdropClick={close}>
            <div style={{width: 360, maxWidth: "100vw"}}>
                <div style={{padding: 10}}>
                    <IconButton autoFocus onClick={close} aria-label="Schliessen">
                        <Icon>clear</Icon>
                    </IconButton>
                </div>
                <img alt="UZH Logo" style={{
                    width: "100%",
                    height: "auto"
                }} src="uzh_logo.svg"/>
                {isSearching &&
                <Search female={props.female} margin={true} autoFocus={true} kennzahlen={props.kennzahlen}
                        selectElement={selectAndClose}/>}
                {
                    (!isSearching && categories.map(value =>
                        <ExpansionPanel key={"cat" + value} style={{margin: 0}}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon/>}><Typography component="div"
                                                                           color={"primary"}><b>{value}</b></Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{margin: 0, paddingTop: 0, paddingBottom: 0}}>
                                <List style={{width: '100%'}}>
                                    {props.kennzahlen.filter(value1 => value1.section === value).map((value2, idx) =>
                                        <ListItem key={'li' + value + idx} button
                                                  onClick={() => selectAndClose(value2)}><ListItemText><Typography
                                            color={"primary"}>{value2.title}</Typography></ListItemText></ListItem>)}
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>)) || undefined}
            </div>
        </Drawer>
        <Slide appear={false} direction="down" in={!trigger}>
            <AppBar position="fixed" color={"primary"}>
                <Toolbar style={{display: "flex", justifyContent: "space-between"}}>
                    <IconButton autoFocus onClick={() => setDrawerOpen(true)} edge="start" color="inherit"
                                aria-label="menu">
                        <Icon>menu</Icon>
                    </IconButton>
                    <Typography variant="h6">
                                <span style={{cursor: "pointer"}}
                                      onClick={() => props.selectElement(undefined)}>CF Guide</span>
                    </Typography>

                    <IconButton onClick={() => search()} color="inherit"
                                aria-label="menu" edge={"start"}>
                        <Icon>search</Icon>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Slide>
        <div style={{
            position: "fixed",
            zIndex: 10,
            bottom: 0,
            right: 0,
            padding: 5,
            backgroundColor: "rgba(220,220,220,0.8)"
        }}>
            <a style={{textDecoration: "none"}} href="https://iten.engineering">
                <Typography component={"footer"}
                            variant="caption" color={"primary"}>
                    Hosted with <Icon color={"error"} fontSize="inherit">favorite</Icon> by Adrian
                </Typography>
            </a>
        </div>
    </>);
};
