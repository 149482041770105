import * as React from "react";
import {Kennzahl} from "../model/Kennzahl";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";

type Props = {
    autoFocus: boolean;
    kennzahlen: Kennzahl[];
    selectElement(k: Kennzahl): void;
    female: boolean;
    margin?: boolean;
}

export const Search: React.FC<Props> = (props: Props) => {

    const [searchText, setSearchText] = React.useState<string>("");
    const filteredElementsFromSearch = searchText ? props.kennzahlen.filter(
        value => (value.section.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || value.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    ) : [];
    const selectElemAndResetText = (e: Kennzahl) => {
        setSearchText("");
        props.selectElement(e);
    };

    const handleTextChange = (e: any) => {
        setSearchText(e.target.value);
    };

    const list = <List>{filteredElementsFromSearch.map(
        (elem, index, arr) => {
            let objsToRender = [];
            objsToRender.push(<ListItem button onClick={() => selectElemAndResetText(elem)}
                                        key={"search-" + index} alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Comic" src={props.female ? elem.femaleImages[0] : elem.maleImages[0]}/>

                </ListItemAvatar>
                <ListItemText
                    primary={elem.title}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                            >
                                {elem.section}
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>);
            if (index < (arr.length - 1)) {
                objsToRender.push(<Divider key={"divider-" + index} variant="fullWidth" component="li"/>)
            }
            return (objsToRender);
        }
    )}</List>;

    return (<>
        <Paper style={{
            width: props.margin ? "calc(100% - 30px)" : "100%",
            display: 'flex',
            alignItems: 'center',
            marginBottom: "20px",
            marginLeft: props.margin ? 15 : 0,
            marginRight: props.margin ? 15 : 0
        }}>
            <InputBase
                style={{flex: 1, marginLeft: 10}}
                placeholder="CF Guide durchsuchen"
                autoFocus={props.autoFocus}
                inputProps={{'aria-label': 'CF Guide durchsuchen'}}
                type="search"
                value={searchText}
                onChange={handleTextChange}
            />
            <IconButton aria-label="search">
                <Icon>search</Icon>
            </IconButton>
        </Paper>
        <>{searchText && list}</>
        {searchText && !filteredElementsFromSearch.length &&
        <Typography variant={"h5"} component="p" style={{textAlign: "center", marginBottom: 10}}>Leider keine
            Ergebnisse</Typography>}
    </>);
};
