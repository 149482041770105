import * as React from "react";
import {useEffect} from "react";
import {Kennzahl} from "../model/Kennzahl";
import theme from "../styles/Theme";
import {GuideNav} from "./GuideNav";
import {
    Avatar,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    Icon,
    IconButton,
    MuiThemeProvider,
    Paper,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import {KennzahlGrid} from "./KennzahlGrid";
import {useCookies} from "react-cookie";
import {KennzahlView} from "./KennzahlView";

const Fade = require('react-reveal/Fade');

export const CFGuide: React.FC = () => {

    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const [cookies, setCookie, removeCookie] = useCookies(['understood']);
    const [genderCookie, setGenderCookie, removeGenderCookie] = useCookies(['gender']);
    const [kennzahlen, setKennzahlen] = React.useState<Kennzahl[]>([]);
    const [selectedKennzahl, setSelectedKennzahl] = React.useState<Kennzahl | undefined>(undefined);
    const showDialog = !cookies['understood'];
    const isFemale = !genderCookie['gender'];

    const understand = () => {
        setCookie("understood", "yes", {maxAge: 31536000});
        window.scrollTo(0, 0);
    };

    const misunderstand = () => {
        removeCookie("understood");
    };

    useEffect(() => {
        setKennzahlen([
            {
                title: "Net Present Value",
                section: "Investitionsrechnung",
                text: "Net Present Value (NPV): Der Nettobarwert einer Investition berechnet sich aus der Summe aller auf " +
                    "den Ausgangszeitpunkt diskontierten Zahlungen abzüglich der Anfangsinvestition: " +
                    "$\\newline NPV = -I_O + \\sum_{t=1}^{T} \\frac{CF_t}{(1+k)^t}$",
                exampleWords: "Du überlegst dir Anfangs Jahr einen eigenen Bier-Zapfhahn für die nächsten WG-Silvester-Parties zu kaufen. " +
                    "Deine 5 Mitbewohnerinnen und Mitbewohner sind begeistert von der Idee, wissen aber auch, wie teuer ein " +
                    "Zapfhahn ist und würden dir bei den ersten 3 Silvester-Parties jeweils ein Betrag von CHF 50.- beisteuern. " +
                    "Du berechnest mittels dem Net Present Value, ob sich die Investition lohnt. ",
                exampleNumeric: "Die Zapfmaschine kostet CHF 700.-, deine Mitbewohnerinnen und Mitbewohner würden dir jeweils " +
                    "CHF 50.- für die ersten 3 Silvesterparties geben. Der aktuelle Zinssatz liegt bei 3%. $\\newline " +
                    "NPV =$ CHF  -700.- + $\\frac{CHF \\; 250.-}{(1+3\\%)^{1}}+ \\frac{CHF \\; 250.-}{(1+3\\%)^{2}}+ \\frac{CHF \\; 250.-}{(1+3\\%)^{3}} =$ CHF 7.15  $\\newline$ " +
                    "Es resultiert ein positiver NPV, womit der Anschaffung und unvergesslichen Silverparties nichts mehr im Weg steht.",
                questions: [{
                    question: "Da die Uni nur noch 2 Jahre geht und du dir nicht sicher bist, ob du nach dem Bachelor im Ausland eine Stelle annehmen willst, " +
                        "kannst du nur noch 2 Parties machen. Wieviel müssten deine Freunde mindestens pro Silvester-Partie bezahlen, " +
                        "dass die Investition für dich in Frage kommt?",
                    answers: ["CHF 350.17", "CHF 365.83", "CHF 212.11", "CHF 377.95"],
                    correctIndex: 1
                }],
                maleImages: ["/1_NPV_m.jpg", "/2_NPV.jpg"],
                femaleImages: ["/1_NPV_w.jpg", "/2_NPV.jpg"],
                legend: [
                    {key: "NPV", value: "Net Present Value"},
                    {key: "$I_0$", value: "Anfangsinvestition"},
                    {key: "t", value: "Beliebiger Zeitpunkt zwischen 0 und T"},
                    {key: "T", value: "Endzeitpunkt"},
                    {key: "$CF_t$", value: "Cashflow zum Zeitpunkt t"},
                    {key: "k", value: "Zinssatz"}
                ],
                complete: true
            },
            {
                title: "Present Value",
                section: "Time Value of Money",
                text: "Zukünftiger Wert einer heute erfolgten Zahlung $(CF_{0})$: " +
                    "$\\newline FV = (1+k)^{t}*PV \\newline$ " +
                    "Heutiger Wert einer am Ende der Periode t erfolgenden, einmaligen Zahlung $(CF_{t})$ " +
                    "bei einem Diskontsatz von k (diskret):" +
                    "$\\newline PV = \\frac{CF_{t}}{(1+k)^{t}} \\newline$ " +
                    "Bei m Zinszahlungen pro Periode (diskret):" +
                    "$\\newline PV = \\frac{CF_{t}}{(1+k\\:* \\frac{1}{m})^{m*t}} \\newline$ " +
                    "Bei stetiger Verzinsung (e als Basis des natürlichen Logarithmus):" +
                    "$\\newline PV = \\frac{CF_{t}}{e^{k*t}} \\newline$" +
                    "Barwert einer Serie von Cashflows:$\\newline PV = \\sum_{t=1}^{T}\\frac{CF_{t}}{(1+k)^{t}}$",
                exampleWords: "Du träumst davon, dir eine eigene Kamera zu kaufen und hast hierfür bereits CHF 800.- gespart. Ausserdem hast du ein Sparkonto von deinen Grosseltern, auf welches du in einem Jahren zugreifen darfst. Der Saldo liegt zum Zeitpunkt, zu welchem du das Geld ausgeben darfst, bei CHF 1'000.-. $\\newline$ " +
                    "Du entdeckst eine neue Kamera für CHF 1'850.- und fragst dich, ob du diese in einem Jahr kaufen kannst? Der Zinssatz liegt bei 3% und der Preis der Kamera ändert sich nicht.$\\newline$ " +
                    "Du sprichst mit dem Verkäufer und erfährst, dass du einen Rabatt von 5% erhältst, wenn du die Kamera noch diese Woche kaufen würdest. Könntest du dir die Kamera leisten, wenn du deine Grosseltern fragst, ob du das Geld sofort haben könntest? Der Zins bleibt bei 3%.",
                exampleNumeric: "Im ersten Fall berechnest du den Future Value deines Vermögens in einem Jahr und merkst, dass die Kamera zu teuer ist. " +
                    "$\\newline FV =$ CHF 800.- * $(1+3\\%)^{1} + $ CHF 1'000.- = CHF 1'824.- $\\newline$ " +
                    "Für den Fall, dass du die Kamera sofort kaufen würdest: Die Kamera kosten nach Abzug des Rabattes noch CHF 1'757.50. Der Present Value deines Vermögens würde reichen, um die Kamera zu kaufen.$\\newline " +
                    "PV = \\frac{CHF \\; 1000.-}{(1+3\\%)^{1}} +$ CHF 800.- = CHF 1'770.87",
                questions: [], maleImages: ["/1_PV_m.jpg", "/2_PV.jpg"], femaleImages: ["/1_PV_w.jpg", "/2_PV.jpg"],
                legend: [
                    {key: "FV", value: "Future Value"},
                    {key: "PV", value: "Present Value"},
                    {key: "CF", value: "Cashflow"},
                    {key: "t", value: "Beliebiger Zeitpunkt zwischen 0 und T"},
                    {key: "T", value: "Endzeitpunkt zum Zeitpunkt t"},
                    {key: "k", value: "Zinssatz"},
                    {key: "m", value: "Anzahl Zinszalung pro Periode (z.B. bei halbjährlicher Verzinsung m = 2)"}
                ],
                complete: true
            }, {
                title: "Ewige Rente",
                section: "Time Value of Money",
                text: "Ewig gleich hoher, periodisch anfallender Zahlungsstrom: $\\newline PV(Ewige\\: Rente) =" +
                    "\\frac{CF}{k} \\newline$ Jährlich um den Faktor g wachsender, periodisch anfallender Zahlungsstrom: " +
                    "$\\newline PV(Ewige\\: Rente) =\\frac{CF}{k-g}$",
                exampleWords: "Du kaufst dir das neue Los der BF-Lotterie und gewinnst den Hauptpreis! Du hast die Möglichkeit, aus 3 Optionen auszuwählen: " +
                    "$\\newline$ 1) Eine Sofortauszahlung von CHF 833'000.- " +
                    "$\\newline$ 2) Eine lebenslängliche Zahlung von CHF 25'000.- pro Jahr " +
                    "$\\newline$ 3) Eine Zahlung von CHF 16'650.- im ersten Jahr und danach eine Erhöhung von 1% bis ans Lebensende $\\newline$" +
                    "Da du in Corporate Finance gut aufgepasst hast, berechnest du den Present Value dieser Zahlungsströme, um sie vergleichen zu können. Der Zinssatz beträgt zurzeit 3%.",
                exampleNumeric: "Du berechnest die Present Values mit den gelernten Formeln und sieht, dass die 2. Variante den höchsten PV aufweist. " +
                    "$\\newline PV(Variante\\: 1) =$ CHF 833'000.- " +
                    "$\\newline PV(Variante\\: 2) =\\frac{CHF \\; 25'000.-}{3\\%} =$ CHF 833'333.33" +
                    "$\\newline PV(Variante\\: 3) =\\frac{CHF \\; 16'650.-}{3\\%-1\\%} =$ CHF 832'500.-",
                questions: [],
                maleImages: ["/1_Ewige_Rente_m.jpg", "/2_Ewige_Rente_m.jpg"],
                femaleImages: ["/1_Ewige_Rente_w.jpg", "/2_Ewige_Rente_w.jpg"],
                legend: [
                    {key: "PV", value: "Present Value"},
                    {key: "CF", value: "Cashflow"},
                    {key: "k", value: "Zinssatz"},
                    {key: "g", value: "Wachstumsrate"}
                ],
                complete: true
            }, {
                title: "Erwartete Rendite",
                section: "Rendite & Risiko",
                text: "Erwartete Rendite: Die erwartete Rendite E(r) " +
                    "für eine Anlage mit N möglichen Rendite-Realisationen $r_i$, i=1,…,N, deren " +
                    "Eintrittswahrscheinlichkeiten $p_i$ betragen: " +
                    "$\\newline E(r)= \\sum_{i=1}^{N}p_{i}*r_{i}$ " +
                    "$=p_{1}*r_{1}+p_{2}*r_{2}+...+p_{n}*r_{n}$",
                exampleWords: "Du siehst am Züri Fäscht ein Glücksrad, bei welchem du zuerst CHF 12.- bezahlen musst und danach " +
                    "einmal am Rad drehen darfst. Dieses ist in 4 Felder aufgeteilt, welche jeweils eine unterschiedliche " +
                    "Rückzahlung aufweisen. Du berechnest deine erwartete Rendite und merkst, dass diese unter dem Einsatzbetrag " +
                    "liegt. Das Spiel generiert in der Erwartung einen Verlust für dich.",
                exampleNumeric: "Die 4 Felder sind unterschiedlich gross: " +
                    "$\\newline$ Feld 1: Wahrscheinlichkeit (50%), Gewinn = CHF 5.-" +
                    "$\\newline$ Feld 2: Wahrscheinlichkeit (16.7%), Gewinn = CHF 12.-" +
                    "$\\newline$ Feld 3: Wahrscheinlichkeit (16.7%), Gewinn = CHF 18.-" +
                    "$\\newline$ Feld 4: Wahrscheinlichkeit (16.7%), Gewinn = CHF 24.-" +
                    "$\\newline E(r_{Gluecksrad})=50\\%*$ CHF 5.- $+ 16.7\\%*$ CHF 12.- $+ 16.7\\%*$ CHF 18.- $+ 16.7\\%*$ CHF 24.- $=$ CHF 11.50",
                questions: [],
                maleImages: ["/1_Erwartete_Rendite_m.jpg", "/2_Erwartete_Rendite_Gedankenblase.jpg"],
                femaleImages: ["/1_Erwartete_Rendite_w.jpg", "/2_Erwartete_Rendite_Gedankenblase.jpg"],
                legend: [
                    {key: "E(r)", value: "Erwartete Rendite"},
                    {key: "$p_i$", value: "Wahrscheinlichkeit von Rendite i"},
                    {key: "$r_i$", value: "Rendite i"}
                ],
                complete: true
            }, {
                title: "Weighted Average Cost of Capital (WACC)",
                section: "Kapitalkosten",
                text: "Der WACC ist die Summe des mit dem jeweiligen Anteil am Gesamtkapital (K) gewichteten Eigen- und Fremdkapitalkostensatzes: " +
                    "$\\newline WACC = k_{FK} * \\frac{FK}{K} + k_{EK} * \\frac{EK}{K}$",
                exampleWords: "Du hast dir Aktien von der Bier AG gekauft. Deine beste Freundin hat sich hingegen für eine " +
                    "Obligation der Bier AG entschieden und erhält einen Coupon von 6% p.a. Du möchtest für deine Aktien " +
                    "eine höhere Entschädigung erhalten, da du auch ein höheres Risiko eingehst. ",
                exampleNumeric: "Die Bier AG berechnet seinen WACC und somit ermitteln, wieviel sie für ihr Kapital durchschnittlich bezahlen müssen. " +
                    "Ihre Kapitalstruktur besteht aus CHF 60 Mio. Fremdkapital und CHF 40 Mio. Eigenkapital. Das Fremdkapital wird zu 6% verzinst, die Eigenkapitalgeber verlangen eine Entschädigung in der Höhe von 8%." +
                    "$\\newline WACC_{Bier AG} = 6\\% * \\frac{CHF \\; 60 Mio.}{CHF \\; 100 Mio.} + 8\\% * \\frac{CHF \\; 40 Mio.}{CHF \\; 100 Mio.} = 6.80\\%$",
                questions: [],
                maleImages: ["/1_WACC_m.jpg", "2_WACC.jpg"],
                femaleImages: ["/1_WACC_w.jpg", "2_WACC.jpg"],
                legend: [
                    {key: "$k_{FK}$", value: "Fremdkapital-Kostensatz"},
                    {key: "$k_{EK}$", value: "Eigenkapital-Kostensatz"},
                    {key: "FK", value: "Fremdkapital"},
                    {key: "EK", value: "Eigenkapital"},
                    {key: "K", value: "Gesamtkapital"}
                ],
                complete: true
            }, {
                title: "Yield to Maturity (YTM)",
                section: "Aktien & Bond Bewertung",
                text: "Yield to Maturity (YTM): Einheitlicher Rendite- bzw. Diskontierungssatz, der sich iterativ aus Marktpreis (= PV), Couponzahlungen und Tilgung herleitet: $\\newline " +
                    "PV_{Obligation}= \\sum_{t=1}^{T} \\frac{C_{t}}{(1+YTM)^{t}}+ \\frac{N_{T}}{(1+YTM)^{T}}$",
                exampleWords: "Du hast auf deinen Geburtstag CHF 1'000 bekommen und bringst diese auf die Bank. " +
                    "Du siehst, dass der Zins für ein Sparkonto, bei welchem das Geld für 3 Jahre gesperrt ist, bei 2% p.a. liegt. " +
                    "Deine beste Freundin, welche eine Obligation (6% Coupon p.a.) der Bier AG besitzt, hat dir erzählt, " +
                    "dass diese ebenfalls noch 3 Jahre läuft. Du fragst den Bankangestellten, wo der aktuelle Kurs dieser " +
                    "Obligation liegt um einen Vergleich anstellen zu können. Dieser liegt heute bei einem Kurs von 112.00%.",
                exampleNumeric: "Du berechnest den YTM: $\\newline 112.00\\% = \\frac{6\\%}{(1+YTM)^{1}}+ \\frac{6\\%}{(1+YTM)^{2}}+ \\frac{106\\%}{(1+YTM)^{3}}$" +
                    "$\\newline YTM = 1.85\\% \\newline$ Du merkst, dass die Obligation trotz hohem Coupon aktuell unattraktiver als ein Sparkonto ist.",
                questions: [], maleImages: ["/1_YTM_m.jpg", "/2_YTM.jpg"], femaleImages: ["/1_YTM_w.jpg", "/2_YTM.jpg"],
                legend: [
                    {key: "PV", value: "Present Value"},
                    {key: "$C_{t}$", value: "Couponzahlung zum Zeitpunkt t"},
                    {key: "T", value: "Endzeitpunkt"},
                    {key: "$N_{T}$", value: "Nennwert Rückzahlung am Ende der Laufzeit"},
                    {key: "YTM", value: "Yield to Maturity"}
                ],
                complete: true
            }] as Kennzahl[]);
    }, []);

    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                fullScreen={xs}
                maxWidth={xs ? false : "xs"}
                open={showDialog}
                onClose={() => understand()}
                scroll={"body"}
            >
                <Card elevation={0}>
                    <CardHeader title="Corporate Finance Guide" subheader="Projekt für Corporate Finance visualisiert"
                                avatar={<Avatar component={"span"}>CF</Avatar>}
                                action={<IconButton onClick={understand}><Icon>close</Icon></IconButton>}>
                    </CardHeader>
                    <CardContent>
                        <div style={{width: "calc(100% + 32px)", marginRight: -16, marginLeft: -16}}>
                            <Fade delay={300}>
                                <img src="/Einstieg.jpg" alt="Willkommen"
                                     style={{width: "100%", height: "auto"}}/>
                            </Fade>
                        </div>
                        <Paper elevation={3} style={{padding: 10, textAlign: "center"}}>
                            <Typography variant={"body2"}>
                                Lisa und Max studieren jeweils Banking und Finance im Bachelor. Wähle jetzt deinen
                                Charakter, um die spannende Welt der Corporate Finance zu entdecken:
                            </Typography>
                            <div style={{width: "100%", maxWidth: 250, margin: "auto"}}>

                                <ButtonGroup component={"div"} fullWidth style={{marginTop: 20, marginBottom: 20}}>
                                    <Button href={undefined}
                                            onClick={() => removeGenderCookie("gender")}>
                                        <img alt="Lisa"
                                             style={{width: "100%", height: "auto", opacity: (isFemale ? 1 : 0.2)}}
                                             src={"/weiblich.jpg"}/>
                                    </Button>
                                    <Button href={undefined}
                                            onClick={() => setGenderCookie("gender", "true", {maxAge: 31536000})}>
                                        <img alt="Max"
                                             style={{width: "100%", height: "auto", opacity: (isFemale ? 0.2 : 1)}}
                                             src={"/männlich.jpg"}/>
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <Button href={undefined} fullWidth variant={"contained"} onClick={() => understand()}
                                    color="primary">
                                Zur Themenübersicht
                            </Button>
                        </Paper>
                    </CardContent>
                </Card>
            </Dialog>
            {selectedKennzahl &&
            <Dialog
                fullScreen={xs}
                open={!!selectedKennzahl}
                onClose={() => setSelectedKennzahl(undefined)}
                scroll={"body"}
                maxWidth={xs ? false : "md"}
            >
                <KennzahlView female={isFemale} kennzahl={selectedKennzahl}
                              close={() => setSelectedKennzahl(undefined)}/>
            </Dialog>}
            <GuideNav female={isFemale} kennzahlen={kennzahlen} selectElement={(e) => {
                setSelectedKennzahl(e);
                if (!e) {
                    misunderstand();
                }
            }}/>
            <div style={{paddingTop: 80}}><KennzahlGrid female={isFemale} kennzahlen={kennzahlen}
                                                        selectElement={setSelectedKennzahl}/>
            </div>
        </MuiThemeProvider>
    );
};
