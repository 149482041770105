import * as React from "react";
import {Kennzahl} from "../model/Kennzahl";
import {
    Avatar,
    Button,
    Card,
    CardContent, CardHeader,
    ExpansionPanel,
    ExpansionPanelDetails, Icon, IconButton,
    MobileStepper,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SwipeableViews from "react-swipeable-views";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import {Quiz} from "./Quiz";

const Latex = require("react-latex");

type Props = {
    kennzahl: Kennzahl
    close: () => void;
    female: boolean;
}
export const KennzahlView: React.FC<Props> = (props: Props) => {
    const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(0);
    const images = props.female ? props.kennzahl.femaleImages : props.kennzahl.maleImages;

    return (
        <Card elevation={0}>
            <CardHeader title={props.kennzahl.title} subheader={props.kennzahl.section}
                        avatar={<Avatar>{props.kennzahl.title[0].toUpperCase()}</Avatar>}
            action={<IconButton onClick={props.close}><Icon>close</Icon></IconButton>}/>
            <CardContent style={{margin: 0, paddingTop: 0}}>
                <SwipeableViews
                    axis={'x'}
                    index={currentImageIndex}
                    onChangeIndex={setCurrentImageIndex}
                    style={{marginLeft: -16, marginRight: -16, width: "calc(100% + 32px)"}}
                    enableMouseEvents
                >
                    {images.map((step, index) => Math.abs(currentImageIndex - index) <= 2 ?
                        <img key={"bild-" + index} style={{width: "calc(100%)", height: "auto"}} src={step}
                             alt="Visualisierung"/> : null)
                    }
                </SwipeableViews>
                <MobileStepper
                    style={{backgroundColor: "transparent", zIndex: 2}}
                    steps={images.length}
                    position="static"
                    variant="dots"
                    activeStep={currentImageIndex}
                    backButton={
                        <Button size="small" onClick={() => setCurrentImageIndex(currentImageIndex - 1)}
                                disabled={currentImageIndex === 0}>
                            {<KeyboardArrowLeft/>}
                            Zurück
                        </Button>
                    }
                    nextButton={
                        <Button size="small" onClick={() => setCurrentImageIndex(currentImageIndex + 1)}
                                disabled={currentImageIndex === images.length - 1}>
                            Vor
                            {<KeyboardArrowRight/>}
                        </Button>
                    }
                />
                <Typography component={"p"} variant={"body1"} style={{padding: 5}}>
                    <Latex>{props.kennzahl.exampleWords}</Latex>
                </Typography>
                <ExpansionPanel style={{margin: 0}}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}><Typography component="div"
                                                                   color={"primary"}>
                        <b>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Icon fontSize={"inherit"} style={{marginRight: 5}}>euro</Icon>
                                <span>Deine Berechnung</span>
                            </div>
                        </b>
                    </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{paddingTop: 0}}>
                        <Latex>{props.kennzahl.exampleNumeric}</Latex>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel style={{margin: 0}}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}>
                        <Typography component="div"
                                    color={"primary"}>
                            <b>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Icon fontSize={"inherit"} style={{marginRight: 5}}>info</Icon>
                                    <span>Theorie</span>
                                </div>
                            </b>
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{paddingTop: 0, display: "block"}}>
                        <div><Latex>{props.kennzahl.text}</Latex></div>
                        <Typography variant="caption" component={"div"} style={{marginTop: 5}}>
                            {props.kennzahl.legend.map(
                                (value, index) => {
                                    return (
                                        <span key={"leg-" + index}
                                              style={{marginRight: 10}}><Latex>{value.key}</Latex>: <Latex>{value.value}</Latex></span>);
                                }
                            )}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {(props.kennzahl.questions.length === 0 && <ExpansionPanel style={{margin: 0}} disabled>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}><Typography component="div"
                                                                   color={"primary"}>
                        <b>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Icon fontSize={"inherit"} style={{marginRight: 5}}>question_answer</Icon>
                                <span>Übungsaufgaben</span>
                            </div>
                        </b>
                    </Typography>
                    </ExpansionPanelSummary>
                </ExpansionPanel>) || <ExpansionPanel style={{margin: 0}}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}><Typography component="div"
                                                                   color={"primary"}>
                        <b>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Icon fontSize={"inherit"} style={{marginRight: 5}}>question_answer</Icon>
                                <span>Übungsaufgaben</span>
                            </div>
                        </b>
                    </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{paddingTop: 0, display: "block", paddingBottom: 10}}>
                        <Quiz questions={props.kennzahl.questions}></Quiz>
                    </ExpansionPanelDetails>
                </ExpansionPanel>}
            </CardContent>
        </Card>
    );
};
