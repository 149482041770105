import * as React from "react";
import {Question} from "../model/Question";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";

const Latex = require("react-latex");

type Props = {
    questions: Question[]
}
export const Quiz: React.FC<Props> = (props: Props) => {
    const [questionIndex, setQuestionIndex] = React.useState<number>(0);
    const [selectedOption, setSelectedOption] = React.useState<number>(-1);
    const [confirmedOption, setConfirmedOption] = React.useState<boolean>(false);

    const resetAnswer = () => {
        setSelectedOption(-1);
        setConfirmedOption(false);
    };

    const nextAnswer = () => {
        setSelectedOption(-1);
        setConfirmedOption(false);
        setQuestionIndex(questionIndex + 1);
    };

    return (<>
        <Typography component="p">
            <Latex>{props.questions[questionIndex].question}</Latex>
        </Typography>
        {!confirmedOption && <>
            <RadioGroup onChange={(e: any) => setSelectedOption(parseInt(e.target.value))}>
                {props.questions[questionIndex].answers.map(
                    (poss, index) => <FormControlLabel key={"radio-" + index}
                                                       value={index}
                                                       checked={selectedOption === index}
                                                       control={<Radio
                                                           color={"primary"}/>}
                                                       label={poss}/>
                )}
            </RadioGroup>

            <Button size="medium" variant={"contained"}
                    color="primary"
                    onClick={() => setConfirmedOption(true)}>
                Beantworten
            </Button>
        </>}
        <>{confirmedOption &&
        <Paper elevation={3} style={{padding: 5, marginTop: 5, textAlign: "center"}}>
            <Typography variant={"h5"}>
                {(confirmedOption && selectedOption === props.questions[questionIndex].correctIndex) ? "Diese Antwort ist richtig!" : "Schade, diese Antwort ist leider falsch!"}
            </Typography>
            {(props.questions && questionIndex < (props.questions.length - 1)) &&
            <div><Button size="large" color="primary" onClick={nextAnswer}>Nächste
                Frage <Icon>forward</Icon></Button>
            </div>}
            <Button color={(confirmedOption && selectedOption === props.questions[questionIndex].correctIndex) ? "secondary" : "primary"} onClick={resetAnswer}>Frage wiederholen <Icon>sync</Icon></Button>
        </Paper>}</>
    </>);
};
