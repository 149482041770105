import * as React from "react";
import {Kennzahl} from "../model/Kennzahl";
import {Container, GridList, GridListTile, GridListTileBar, useMediaQuery} from "@material-ui/core";
import theme from "../styles/Theme";

type Props = {
    kennzahlen: Kennzahl[];
    selectElement(k: Kennzahl | undefined): void;
    female: boolean;
}
export const KennzahlGrid: React.FC<Props> = (props: Props) => {

    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));

    return (<Container>
        <GridList cellHeight={"auto"} cols={xs ? 1 : lg ? 3 : 2}>
            {props.kennzahlen.filter(value => value.complete).map((kennzahl, index) => (
                <GridListTile onClick={() => props.selectElement(kennzahl)} style={{cursor: "pointer"}} key={"kennz-" + index}>
                    <img src={props.female ? kennzahl.femaleImages[0] : kennzahl.maleImages[0]} style={{width: "100%", height: "auto"}} alt={kennzahl.title}/>
                    <GridListTileBar
                        title={kennzahl.title}
                        subtitle={<span>{kennzahl.section}</span>}
                    />
                </GridListTile>
            ))}
        </GridList>
    </Container>);
};
